import api from '@/utils/api';

export function contact(payload) {
  return api.post('aimePc/contact', payload);
}
export function getDicItemByCode(payload) {
  return api.get('aimePc/getDicItemByCode', payload);
}
export function recruitmentList(payload) {
  return api.get('aimePc/recruitmentList', payload);
}